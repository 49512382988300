import { sendRequest } from '../RequestService';
import { IAnswerQuestion, IAnswers, IBodyGET, IChallengeConcluded, IChallenge, IContent, IGETChallenge, IQuestions, IChallengeAccomplished, IPOSTResponse, IChallengeCompleted, IResponteContentChallenge, IPUTResponse, IMyPoints, IGiftsAndProducts, ICertificates, IPostReportChallenge, IPostChallenge, IArrayQuestions, IExtract, IChallengeExecution } from './types';

const defaultRoot = 'desafios';

export const deleteChallenges = async (id: string) => {
  const url = `challenge-definitions/${id}`
  return await sendRequest<boolean, boolean>(url, 'DELETE')
}

export const getChallenges = async (body: IBodyGET) => {
  let url = `challenge-definitions/all-actives-sorted`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Title=${body.Nome}`;
  if (body?.Ativo) url += `&Ativo=${body.Ativo}`;
  if (body?.CategoriaId) url += `&Category=${body.CategoriaId}`;
  if (body?.Id) url += `&Id=${body.Id}`;
  if (body?.HasSpecialAward) url += `&HasSpecialAward=${body.HasSpecialAward}`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<IChallenge[], IBodyGET>(url, 'GET')
}

export const getChallengesCreated = async (body: IBodyGET) => {
  let url = `challenge-definitions/actives`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Title=${body.Nome}`;
  if (body?.CategoriaId) url += `&Category=${body.CategoriaId}`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<IChallengeAccomplished[], IBodyGET>(url, 'GET')
}

export const getChallengesPerformed = async (body: IBodyGET) => {
  let url = `challenge-definitions/in-revisions`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Title=${body.Nome}`;
  if (body?.CategoriaId) url += `&Category=${body.CategoriaId}`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<IChallengeAccomplished[], IBodyGET>(url, 'GET')
}

export const getChallengeDetailsRejected = async (id: string) => {
  const url = `challenge-definitions/repproveds/${id}/details`
  return await sendRequest<IChallengeAccomplished, IBodyGET>(url, 'GET')
}

export const getChallengeDetailsUnderReview = async (id: string) => {
  const url = `challenge-definitions/in-revisions/${id}/details`
  return await sendRequest<IChallengeAccomplished, IBodyGET>(url, 'GET')
}

export const getChallengeDetailsActive = async (id: string) => {
  const url = `challenge-definitions/actives/${id}/details`
  return await sendRequest<IChallengeAccomplished, IBodyGET>(url, 'GET')
}

export const getSearchChallengeQuiz = async (id: string) => {
  const url = `challenge-definitions/${id}/quizzes`
  return await sendRequest<IArrayQuestions[], IBodyGET>(url, 'GET')
}

export const getChallenge = async (id?: string) => {
  const url = `${defaultRoot}/conteudos?Id=${id}`
  return await sendRequest<IContent, boolean>(url, 'GET')
}

export const getRetrieveContent = async (id?: string) => {
  const url = `${defaultRoot}/conteudos?Id=${id}`
  return await sendRequest<IResponteContentChallenge, boolean>(url, 'GET')
}

export const postQuestion = async (body: FormData) => {
  const url = `${defaultRoot}/perguntas`
  return await sendRequest<string, FormData>(url, 'POST', body)
}

export const putQuestion = async (body: FormData, id?: string) => {
  const url = `${defaultRoot}/perguntas/${id}`
  return await sendRequest<string, FormData>(url, 'PUT', body)
}

export const postResponse = async (body: IPOSTResponse[], id?: string) => {
  const url = `${defaultRoot}/respostas/${id}`
  return await sendRequest<string, IPOSTResponse[]>(url, 'POST', body)
}

export const putResponse = async (body: IPUTResponse[], id?: string) => {
  const url = `${defaultRoot}/respostas/${id}`
  return await sendRequest<string, IPUTResponse[]>(url, 'PUT', body)
}

export const postChallenge = async (body: IPostChallenge) => {
  const url = `challenge-definitions`
  return await sendRequest<string, IPostChallenge>(url, 'POST', body)
}

export const putChallenge = async (body: IPostChallenge, id?: string) => {
  const url = `challenge-definitions/${id}`
  return await sendRequest<boolean, IPostChallenge>(url, 'PUT', body)
}

export const putInactivateChallenge = async (body: FormData) => {
  const url = `${defaultRoot}/inativar-desafio`
  return await sendRequest<boolean, FormData>(url, 'PUT', body)
}

export const getChallengeCompleted = async (body: IBodyGET) => {
  const url = `${defaultRoot}/completos?Id=${body.Id}`
  return await sendRequest<IChallengeCompleted[], IBodyGET>(url, 'GET')
}

export const getQuestions = async (body: IBodyGET) => {
  let url = `${defaultRoot}/perguntas?DesafioConteudoId=${body.Id}`
  url += `&Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  return await sendRequest<IQuestions[], boolean>(url, 'GET')
}

export const getAnswers = async (id: string) => {
  const url = `${defaultRoot}/respostas?PerguntaId=${id}`
  return await sendRequest<IAnswers, boolean>(url, 'GET')
}

export const getChallengeFinished = async (body: IGETChallenge) => {
  let url = `${defaultRoot}/recuperar-desafios-concluido`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.DesafioId) url += `&DesafioId=${body.DesafioId}`;
  return await sendRequest<IChallengeConcluded[], IGETChallenge>(url, 'GET')
}

export const getChallengeConcluded = async (body: IGETChallenge) => {
  let url = `${defaultRoot}/recuperar-desafios-concluido`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.DesafioId) url += `&DesafioId=${body.DesafioId}`;
  return await sendRequest<IChallengeConcluded[], IGETChallenge>(url, 'GET')
}

export const deleteQuestion = async (id?: string) => {
  const url = `${defaultRoot}/perguntas/${id}`
  return await sendRequest<boolean, boolean>(url, 'DELETE')
}

export const deleteResponse = async (id?: string) => {
  const url = `${defaultRoot}/respostas/${id}`
  return await sendRequest<boolean, boolean>(url, 'DELETE')
}

export const getMyPoints = async () => {
  const url = `user-wallets/points`
  return await sendRequest<IMyPoints, boolean>(url, 'GET')
}

export const getExtract = async (body: IGETChallenge) => {
  let url = `user-wallets/extract`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  return await sendRequest<IExtract[], boolean>(url, 'GET')
}

export const getGiftsAndProducts = async () => {
  const url = `${defaultRoot}/meus_produtos_e_brindes`
  return await sendRequest<IGiftsAndProducts, boolean>(url, 'GET')
}

export const getCertificates = async () => {
  const url = `${defaultRoot}/meus_certificados`
  return await sendRequest<ICertificates[], boolean>(url, 'GET')
}

export const postReportChallenge = async (body: IPostReportChallenge, id?: string) => {
  const url = `challenges-complaints/${id}`
  return await sendRequest<boolean, IPostReportChallenge>(url, 'POST', body)
}

export const getWallet = async () => {
  const url = `user-wallets/balance`
  return await sendRequest<{ Balance: number }, boolean>(url, 'GET')
}

export const getChallengesDataPending = async (id?: string) => {
  const url = `challenges-executions/${id}`
  return await sendRequest<IChallengeAccomplished, IBodyGET>(url, 'GET')
}

export const getChallengeExecution = async (id: string) => {
  const url = `challenge-definitions/pre-execution/${id}`
  return await sendRequest<IChallenge, string>(url, 'GET')
}

export const postStartChallenge = async (id: string) => {
  const url = `challenges-executions`
  return await sendRequest<string, { ChallengeId: string }>(url, 'POST', { ChallengeId: id })
}

export const postStartExecution = async (id?: string) => {
  const url = `challenges-executions/${id}`
  return await sendRequest<IChallengeExecution, string>(url, 'GET')
}

export const postAnswerQuestion = async (body: IAnswerQuestion) => {
  const url = `challenges-executions/answers`
  return await sendRequest<boolean, IAnswerQuestion>(url, 'POST', body)
}

export const getChallengesPending = async (body: IBodyGET) => {
  let url = `challenges-executions`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Name) url += `&Title=${body.Name}`;
  if (body?.Category) url += `&CategoryId=${body.Category}`;
  if (body?.ChallengesDisabled) url += `&IsCompleted=${body.ChallengesDisabled}`;
  if (body?.DifficultyLevel) url += `&DifficultyLevel=${body.DifficultyLevel}`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<IChallengeAccomplished[], IBodyGET>(url, 'GET')
}

export const getChallengesDataPerformed = async (id?: string) => {
  const url = `challenges-executions/${id}`
  return await sendRequest<IChallengeAccomplished, IBodyGET>(url, 'GET')
}

export const getChallengesFailed = async (body: IBodyGET) => {
  let url = `challenge-definitions/repproveds`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Title=${body.Nome}`;
  if (body?.CategoriaId) url += `&Category=${body.CategoriaId}`; 
  url += `&OrderByProperty=-Id`;
  return await sendRequest<IChallengeAccomplished[], IBodyGET>(url, 'GET')
}

export const postFinishChallenge = async (id: string) => {
  const url = `challenges-executions/finish/${id}`
  return await sendRequest<boolean, string>(url, 'PATCH')
}